import GenericTemplate from "../../genericTemplate/GenericTemplate";
import Dashboard from "../../dashboard/Dashboard";

export const HomePage = () => {
  return (
    <GenericTemplate title="Home">
      <div><Dashboard /></div>
    </GenericTemplate>
  );
};
