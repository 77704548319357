import ReactPaginate from 'react-paginate'
import React from 'react'
import NumberUtil from './NumberUtil'
import { AnyAction } from '@reduxjs/toolkit'
import './design_pagination.css';
import { useNavigate} from 'react-router-dom';

type PaginationProps = {
    dataCounts: number;
    setStart: (page: number) => void;
    numberOfDisplaysPerpage: number;
    currentPage: number;
  };

const Pagination = (props: PaginationProps) => {
const { dataCounts, setStart, numberOfDisplaysPerpage, currentPage } = props
const totalPageCount = NumberUtil.roundByCeil(dataCounts, numberOfDisplaysPerpage)

const navigate = useNavigate();
// ページクリック時のイベント
const handlePaginate = (selectedPage:any) => {
  // selectedPage.selectedには、ページ番号 - 1が入る
  const page = selectedPage.selected * numberOfDisplaysPerpage
  setStart(page)
  navigate(`?page=${selectedPage.selected + 1}`)
}

return (
  <ReactPaginate
    forcePage={currentPage} // 現在のページをreactのstateで管理したい場合等
    pageCount={totalPageCount}
    onPageChange={handlePaginate}
    marginPagesDisplayed={2} // 先頭と末尾に表示するページ数
    pageRangeDisplayed={3} // 現在のページの前後をいくつ表示させるか
    containerClassName="pagination justify-center" // ul(pagination本体)
    pageClassName="page-item" // li
    pageLinkClassName="page-link rounded-full" // a
    activeClassName="active" // active.li
    activeLinkClassName="active" // active.li < a
    
    // 戻る・進む関連
    previousClassName="page-item" // li
    nextClassName="page-item" // li
    previousLabel={'<'} // a
    previousLinkClassName="previous-link"
    nextLabel={'>'} // a
    nextLinkClassName="next-link"
   
    // 先頭 or 末尾に行ったときにそれ以上戻れ(進め)なくする
    disabledClassName="disabled-button d-none"
   
    // 中間ページの省略表記関連
    breakLabel="..."
    breakClassName="page-item"
    breakLinkClassName="page-link"
  />
)
}

export default Pagination