import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import { useSelector,useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import {
  selectStats,
} from "./statsSlice";
import {
  updateStatsTable,
} from "./statsTableSlice";
import axios from "axios";

const PieChart = (props: any) => {
  const dispatch: AppDispatch = useDispatch();
  const [chartWidth, setChartWidth] = useState<number>(0);
  const chartRef = useRef<HTMLDivElement>(null);

  const [GraphTitle, setGraphTitle] = useState<string>('SAMPLE');
  const [AnnotateText, setAnnotateText] = useState<string>('SAMPLE');
  const [GraphNumber, setGraphNumber] = useState<number[]>([1, 1, 1]);
  //検索条件を書いたリスト
  const [exploreList, setExploreList] = useState<
    { column_name: string; operator: string; value: string }[]
  >([]);
  const [postData, setPostData] = useState<any>(
    {
      search_condition: [],
      stat_method: "exceed_no",
      x1: 2,
      x2: 10,
      sort_index: "exceed_no",
      page: 1,
    }
  );
  const [GraphLabel, setGraphLabel] = useState<string[]>(
    ['安全', '注意', '警報']);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const parentWidth = chartRef.current.parentElement?.clientWidth || 0;
        setChartWidth(parentWidth * 0.9);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window]);

  const apiUrlPost = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/alarm_stats_list/`;
  const stats = useSelector(selectStats);

  useEffect(() => {
    setExploreList([]);
    if(stats.plant_id >0){
      setExploreList((prevList) => [
        ...prevList,
        {
          column_name: "plant_id",
          operator: "=",
          value: stats.plant_id.toString(),
        },
      ]);
    }

    if(stats.machine_type_id >0){
      setExploreList((prevList) => [
        ...prevList,
        {
          column_name: "machine_kind",
          operator: "=",
          value: stats.machine_type_name,
        },
      ]);
    }

    if(stats.tag_id >0){
      setExploreList((prevList) => [
        ...prevList,
        {
          column_name: "tag_name",
          operator: "=",
          value: stats.tag_name,
        },
      ]);
    }
  }, [stats ]);

  //次に検索リストを元にデータを取得する
  useEffect(() => {
    if (stats.stats_type === "exceed_no") {
      const dpost = {
        search_condition: exploreList,
        stat_method: "exceed_no",
        x1: 2,
        x2: 10,
        sort_index: "exceed_no",
        page: stats.page,
      };
      setPostData(dpost);
      setGraphLabel(['2回未満', '10回未満', '10回以上']);
      setGraphTitle('センサー数( 計測値>閾値 (直近ひと月) )');
    } else if (stats.stats_type === "flagflag_date") {
      const dpost = {
        search_condition: exploreList,
        stat_method: "flagflag",
        sort_index: "dateWarning",
        page: stats.page
      }
      setPostData(dpost);
      setGraphLabel(['危険度低', '危険度中', '危険度大']);
      setGraphTitle('センサー数( 予測値 > 閾値 )');
    } else if (stats.stats_type === "flagflag") {
      const dpost = {
        search_condition: exploreList,
        stat_method: "flagflag",
        sort_index: "indicatorWarning",
        page: stats.page
      };
      setPostData(dpost);
      setGraphTitle('センサー数( 予測値 > 閾値 )');
      setGraphLabel(['危険度低', '危険度中', '危険度大']);
    } else {
      console.log("NO SUPPORTE STATS TYPE");
    };

  }, [exploreList]);

  //最後にAPIからデータを取得して、各providerに渡す
  useEffect(() => {
    console.log('POST DATA')
    axios.post(
      apiUrlPost, postData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${localStorage.localJWT}`,
        },
      }
    ).then((res) => {
      const classdata = res.data.classification;
      setGraphNumber([
        classdata.class1.number,
        classdata.class2.number,
        classdata.class3.number
      ]);
      setAnnotateText('全件数：' + classdata.all.number.toString() + '件');
      //statsTableの更新
      dispatch(updateStatsTable(res.data.table));
    });
  }, [postData]);

  const dataLabel = GraphLabel;
  const dataNumeric = GraphNumber;
  const layout = {
    width: chartWidth,
    height: 460,
    title: {
      text: GraphTitle,
      x: 0.5,
      y: 0.95
    },
    margin: {
      l: 0, // 左側の余白
      r: 20, // 右側の余白
      t: 10, // 上側の余白
      b: 0, // 下側の余白
    },
    legend: {
      orientation: 'h' as 'h',
      x: 0.1,
      y: 0.1, // 凡例の位置をグラフの上に設定
    },
    annotations: [
      {
        text: AnnotateText,
        x: 0.5, // アノテーションの位置（0から1の範囲で指定）
        y: 0.5,
        showarrow: false // 矢印を非表示にする場合はtrueに設定
      }
    ]
  };

  return (
    <div ref={chartRef} style={{ width: '100%',marginTop: '30px' }}>
      <Plot data={
        [{
          labels: dataLabel,
          values: dataNumeric,
          type: 'pie',
          hole: 0.5, // 円グラフの中心部分を空けるために hole プロパティを追加
          textinfo: 'label+percent', // 中心に表示するテキストのフォーマットを指定
          textposition: 'inside'
        }]
      } layout={layout} />
    </div>
  );
}

export default PieChart;