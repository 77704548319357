import React, { useState, ChangeEvent } from "react";
import { FormControl, InputLabel, MenuItem, makeStyles } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useSelector,useDispatch } from "react-redux";
import { selectStats, updateStatsMethod } from "./statsSlice";

function StatsDropdownMenu() {
  // const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState('flagflag_date');
  const dispatch = useDispatch();
  // const handleOptionChange = (event: ChangeEvent<{ value: unknown }>) => {
  //   setSelectedOption(event.target.value as string);
  // };
  const handleOptionChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value as string);
    const newPlantData = {
      stats_type: event.target.value as string,
    };
    dispatch(updateStatsMethod(newPlantData));
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="dropdown-label">Select Stats</InputLabel>
      <Select
        labelId="dropdown-label"
        value={selectedOption}
        onChange={handleOptionChange}
      >
        <MenuItem value="flagflag_date">予測値集計(警戒日順)</MenuItem>
        <MenuItem value="flagflag">予測値集計(危険度順)</MenuItem>
        <MenuItem value="exceed_no">1月以内に閾値オーバー</MenuItem>
      </Select>
    </FormControl>
  );
}

export default StatsDropdownMenu;
