import { Routes, Route } from "react-router-dom";
import { HomePage } from "./components/pages/HomePage";
import { LoginPage } from "./components/pages/LoginPage";
import { SignUpPage } from "./components/pages/SignUpPage/index";
import { SensorPage } from "./components/pages/SensorPage";
import { SensorDetailPage } from "./components/pages/SensorDetailPage";
import { MailPage } from "./components/pages/MailPage";
import { NotFoundPage } from "./components/pages/NotFoundPage";
import { SettingPage } from "./components/pages/SettingPage";
import { UserConfirmPage } from "./components/pages/UserConfirmPage";
import { GuestRoute, PrivateRoute } from "./AuthRouter";

export const App = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<GuestRoute children={<LoginPage />}></GuestRoute>}
          // element={<LoginPage />}
        />
        <Route
          path="/signup"
          element={<GuestRoute children={<SignUpPage />}></GuestRoute>}
        />
        <Route
          path="/home"
          element={<PrivateRoute children={<HomePage />}></PrivateRoute>}
        />
        <Route
          path="/userconfirm"
          element={<PrivateRoute children={<UserConfirmPage />}></PrivateRoute>}
        />
        <Route
          path="/sensor"
          element={<PrivateRoute children={<SensorPage />}></PrivateRoute>}
        />
        <Route path="/prediction" element={<SensorDetailPage />} />
        <Route
          path="/report"
          element={<PrivateRoute children={<MailPage />}></PrivateRoute>}
        />
        <Route
          path="/setting"
          element={<PrivateRoute children={<SettingPage />}></PrivateRoute>}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};
