import React, { useState,useEffect } from "react";
import Draggable from 'react-draggable';
import ScrollContainer from "react-indiana-drag-scroll";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
} from "@mui/material";
import { styled } from '@mui/system';
import { useSelector ,useDispatch } from "react-redux";
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import {
  selectStatsTable,
} from "./statsTableSlice";
import { selectStats, updatePage } from "./statsSlice";
import { Link } from "react-router-dom";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import IconButton from '@mui/material/IconButton';
import Pagination from "./pagenation";
import BasicContainer from "@mui/material/Container";
import { useLocation } from "react-router-dom";

// スタイルの定義
const StyledTableContainer = styled(TableContainer)({
  maxWidth: '100%',
  marginTop: '10px'
});

const StyledTable = styled(Table)({
  tableLayout: 'auto',
});

const StyledTableCell = styled(TableCell)({
  whiteSpace: 'nowrap',
  padding: '8px',
  borderBottom: '1px solid #ccc',
  fontSize: '16px'
});

const TableCellContents = styled(TableCell)({
  whiteSpace: 'nowrap',
  padding: '5px',
  borderBottom: '1px solid #ccc',
  fontSize: '12px'
});

const SortableTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [sortKey, setSortKey] = useState("");
  const [sortDirection, setSortDirection] = useState<string | undefined>("asc");

  const handleSort = (key: string) => {
    if (key === sortKey) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortDirection("asc");
    }
  };


  const statsTable = useSelector(selectStatsTable).statsTable;
  const data = Object.values(statsTable);
  const sortedData = data.sort((a, b) => {
    const valueA = a[sortKey as any as keyof typeof a];
    const valueB = b[sortKey as any as keyof typeof a];

    if (valueA < valueB) {
      return sortDirection === "asc" ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleLinkClick = (event:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const href = (event.currentTarget as HTMLAnchorElement).getAttribute('href');
    if (href) {
      window.open(href, '_blank');
    }
  };
  const handleSetStart = (page: number) => {};
  const [page, setPage] = useState(0);
  const numberOfDisplaysPerpage: number = 10;
  const [total_count, setTotalCount] = useState(100);
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryMachineId = searchParams.get("page");
    if (queryMachineId) {
      const mid: number = parseInt(queryMachineId);
      //整数に変換してからセットする
      dispatch(updatePage(mid));
    }
  }, [location.search]);
  return (
    
    <StyledTableContainer >
      <ScrollContainer>
    <StyledTable>
      <TableHead>
        <TableRow>
          <StyledTableCell style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#fff',borderRight: '2px solid #ccc' }}>
            <TableSortLabel
              active={sortKey === "machine_name"}
              direction="asc"
              onClick={() => handleSort("machine_name")}
              style={{ color: "black", fontWeight: "bold" }}
            >
              機器
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell>
            <TableSortLabel
              active={sortKey === "flagWarning"}
              direction="asc"
              onClick={() => handleSort("flagWarning")}
              style={{ color: "black", fontWeight: "bold" }}
            >
              予測状態
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell>
            <TableSortLabel
              active={sortKey === "machine_name"}
              direction="asc"
              onClick={() => handleSort("machine_name")}
              style={{ color: "black", fontWeight: "bold" }}
            >
              グラフ
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell>
            <TableSortLabel
              active={sortKey === "exceed_no"}
              direction="asc"
              onClick={() => handleSort("exceed_no")}
              style={{ color: "black", fontWeight: "bold" }}
            >
              超過回数
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell>
            <TableSortLabel
              active={sortKey === "row.indicatorWarning"}
              direction="asc"
              onClick={() => handleSort("row.indicatorWarning")}
              style={{ color: "black", fontWeight: "bold" }}
            >
              危険度
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell>
            <TableSortLabel
              active={sortKey === "max_value"}
              direction="asc"
              onClick={() => handleSort("max_value")}
              style={{ color: "black", fontWeight: "bold" }}
            >
              一年後<br />予測値
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell>
            <TableSortLabel
              active={sortKey === "dateWarning"}
              direction="asc"
              onClick={() => handleSort("dateWarning")}
              style={{ color: "black", fontWeight: "bold" }}
            >
              予測<br />警戒日
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell>
            <TableSortLabel
              active={sortKey === "dateAlarm"}
              direction="asc"
              onClick={() => handleSort("dateAlarm")}
              style={{ color: "black", fontWeight: "bold" }}
            >
              メンテナンス<br />推奨日
            </TableSortLabel>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          sortedData.map((row) => (
            <TableRow key={row.machine_id}>
              <TableCellContents style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#fff' ,borderRight: '2px solid #ccc' }}>
                {row.machine_name}
                </TableCellContents>
                <TableCellContents >
                {row.flagWarning && row.flagAlarm && <AutoFixOffIcon sx={{ color: '#E64C4C' }} />}
                {row.flagWarning && !row.flagAlarm && <AutoFixOffIcon sx={{ color: '#E64C4C' }} />}
                {!row.flagWarning && row.flagAlarm && <AutoFixNormalIcon sx={{ color: '#D8AA46' }}/>}
                {!row.flagWarning && !row.flagAlarm && <AutoFixHighIcon sx={{ color: '#6EB55F' }}/>}
                </TableCellContents>
              <TableCellContents>
                  <Link
                    to={`/prediction/?machine_id=${row.machine_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleLinkClick}
                  >
                    <IconButton >
                    <AutoGraphIcon color="primary"/>
                    </IconButton>
                  </Link>
                </TableCellContents>
              <TableCellContents>{row.exceed_no}</TableCellContents>
              <TableCellContents>{row.indicatorWarning !== null ?
                row.indicatorWarning.toFixed(1) : "-"}
               </TableCellContents>
               <TableCellContents>{row.max_value !== null ?
                row.max_value.toFixed(1) : "-"}
               </TableCellContents>
               <TableCellContents style={{ whiteSpace: 'nowrap' }}>
                {row.dateWarning.startsWith('9999') ? '-' : row.dateWarning.substring(0, 10)}
                </TableCellContents>
               <TableCellContents style={{ whiteSpace: 'nowrap' }}>
                {row.dateAlarm.startsWith('9999') ? '-' : row.dateWarning.substring(0, 10)}
                </TableCellContents>
            </TableRow>
          ))
        }
      </TableBody>
    </StyledTable>
    </ScrollContainer>
    <BasicContainer className="container-stock__pagination mt-4">
        <Pagination
          dataCounts={total_count}
          setStart={handleSetStart}
          numberOfDisplaysPerpage={numberOfDisplaysPerpage}
          currentPage={page}
        />
      </BasicContainer>
    </StyledTableContainer>    
    
  );
};

export default SortableTable;
