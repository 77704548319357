import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";

const apiUrlGet = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/sensor_list/`;
export const fetchAsyncGetSensors = createAsyncThunk("sensor/get", async () => {
  const res = await axios.get(apiUrlGet, {
    headers: {
      Authorization: `JWT ${localStorage.localJWT}`,
    },
  });
  console.log(res.data);
  return res.data;
});


export const sensorSlice = createSlice({
  name: "sensor",
  initialState: {
    isLoadingSensor: false,
    openNewPost: false,
    isSubmitting: false,
    sensors: [
      {
        machine_id: 0,
        name: "",
        nickname: "",
        nickname_alert: "",
        plant_id: 0,
        plant__name: "",
        plant__name_jp: "",
        sensor_kind: "",
        machine_kind: "",
        threshold: 0,
        sensor_used_tag: "",
        settings_id: "",
        created_at: "",
        updated_at: "",
      },
    ],
  },
  reducers: {
    fetchSensorStart(state) {
      state.isLoadingSensor = true;
    },
    fetchSensoEnd(state) {
      state.isLoadingSensor = false;
    },
    updateSubmitting(state,action) {
      const isSubmit = action.payload;
      state.isSubmitting = isSubmit;
    },
    updateSensors: (state, action) => {
      const newSensorList = action.payload;
      // 更新したセンサーをstate内のセンサーリストから探し、置換する処理を記述する
      state.sensors = newSensorList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncGetSensors.fulfilled, (state, action) => {
      state.sensors = action.payload;
    });
  },
});

export const { fetchSensorStart, fetchSensoEnd } = sensorSlice.actions;

//storeした値を参照したい際の準備
export const selectIsLoadingSensor = (state: RootState) =>
  state.sensor.isLoadingSensor;
export const selectIsSubmitting = (state: RootState) =>
  state.sensor.isSubmitting;
export const selectSensors = (state: RootState) => state.sensor.sensors;
export const { updateSensors,updateSubmitting } = sensorSlice.actions;
export default sensorSlice.reducer;
