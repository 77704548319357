import { useState, useEffect } from "react";
import { checkJwt } from "../api";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
// import { useCookies } from "react-cookie";

const apiUrl = process.env.REACT_APP_DEV_API_URL;

export const useAuth = () => {
  const [check, setCheck] = useState<{
    checked: boolean;
    isAuthenticated: boolean;
  }>({ checked: false, isAuthenticated: false });

  // const [cookies, setCookie, removeCookie] = useCookies([
  //   "accesstoken",
  //   "refreshtoken",
  // ]);
  // Authorization: `JWT ${cookies.get("accesstoken")}`,
  useEffect(() => {
    const handleCheckJwt = async () => {
      try {
        await axios
          .get(`${apiUrl}accounts/user/`, {
            headers: {
              Authorization: `JWT ${localStorage.localJWT}`,
            },
          })
          .then((res: AxiosResponse) => {
            const { data, status } = res;

            // console.log(token);
            if (status === 200) {
              console.log("useAuth Success");
              setCheck({
                checked: true,
                isAuthenticated: true,
              });
            } else {
              setCheck({
                checked: true,
                isAuthenticated: false,
              });
            }
          });
      } catch {
        console.log("useAuth Failed");
        setCheck({ checked: true, isAuthenticated: false });
      }
    };
    handleCheckJwt();
  }, []);

  return check;
};
