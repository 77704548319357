import React, { useEffect, useState, ChangeEvent } from "react";
import styles from "./Post.module.css";

// import { Table } from "@material-ui/core";
// basicContainerのインポート
import InputAdornment from '@mui/material/InputAdornment';
import BasicContainer from "@mui/material/Container";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { Typography, Box } from '@mui/material';
import {
  updateSubmitting,
  selectIsLoadingSensor,
  selectIsSubmitting,
  selectSensors,
  updateSensors,
} from "./sensorSlice";

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { PROPS_SENSOR } from "../types";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from '@mui/material/Avatar';
import { Link } from "react-router-dom";
import ModalContent from "./DetailModal";
import Pagination from "./pagenation";
import DropdownPlant from "../genericTemplate/DropdownPlant";
import StructureThreeColumns from '../parts/__structures/StructureThreeColumns';
import PlantDropdownMenu from './PlantDropDown';
import TagDropdownMenu from './TagDropDown';

import {selectStats} from "../dashboard/statsSlice";
import {sensorSearch} from "./sensorSearchSlice";
// TBLのモデル定義
function createData(
  machine_id: string,
  name: string,
  nickname: string,
  plant_id: string,
  plant__name: string,
  plant__name_jp: string,
  sensor_kind: string,
  settings_id: string,
  created_at: Date,
  updated_at: Date
) {
  return {
    machine_id,
    name,
    nickname,
    plant_id,
    plant__name,
    plant__name_jp,
    sensor_kind,
    settings_id,
    created_at,
    updated_at,
  };
}

function getColorByCharacter(character:string) {
  const colorCount = 5;
  const characterCode = character.charCodeAt(0);
  const colorIndex = Math.floor((characterCode % colorCount) + 1);

  const characterColors: { [key: string]: string } = {
    '1': 'red',
    '2': 'blue',
    '3': 'green',
    '4': 'yellow',
    '5': 'purple',
  };

  return characterColors[colorIndex] || 'black';
}

const Sensors: React.FC = () => {
  //クエリパラメータを取得する
  const location = useLocation();

  // 編集モーダルの開閉状態
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMachineId, setSelectedMachineId] = useState<
    number | undefined
  >(undefined);
  // テーブルセルをクリックした際の処理
  const handleOpenModal = (machineId: number) => {
    setSelectedMachineId(machineId);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [key, setKey] = useState(0);
  function handlePageChange() {
    // ページが切り替わったときにキーを変更して再レンダリングをトリガーする
    setKey(key + 1);
  }
  const dispatch: AppDispatch = useDispatch();
  const sensors = useSelector(selectSensors);
  const sensorSearchs = useSelector(selectSensors);
  const isUploaded = useSelector(selectIsSubmitting);
  //ページ番号
  const [page, setPage] = useState(0);
  const [total_count, setTotalCount] = useState(100);
  const numberOfDisplaysPerpage: number = 10;
  const handleSetStart = (page: number) => {};
  const [PageNo, setPageNo] = useState<number | undefined>(undefined);

  //名前の検索
  const [nameInputValue, setNameInputValue] = useState("");
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNameInputValue(event.target.value);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryMachineId = searchParams.get("page");
    if (queryMachineId) {
      const mid: number = parseInt(queryMachineId);
      //整数に変換してからセットする
      setPageNo(mid);
    }
  }, [location.search]);
  //plantとtagの検索
  const stats = useSelector(sensorSearch);
  //検索条件を書いたリスト
  const [exploreList, setExploreList] = useState<
    { column_name: string; operator: string; value: string }[]
  >([]);
  useEffect(() => {
    console.log(stats);
    setExploreList([]);
    if (nameInputValue !== "") {
      setExploreList((prevList) => [
        ...prevList,
        {
          column_name: "name",
          operator: "=",
          value: nameInputValue,
        },
      ]);
    }
    if(stats.plant_id >0){
      setExploreList((prevList) => [
        ...prevList,
        {
          column_name: "plant_id",
          operator: "=",
          value: stats.plant_id.toString(),
        },
      ]);
    }
    if(stats.tag_id >0){
      setExploreList((prevList) => [
        ...prevList,
        {
          column_name: "tag_name",
          operator: "=",
          value: stats.tag_name,
        },
      ]);
    }
    dispatch(updateSubmitting(false));
  }, [nameInputValue,stats ]);
  //検索の実行
  useEffect(() => {
    const apiUrlPost = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/sensor_list/`;
    const fetchData = async () => {
      try {
        // もし nameInputValue が空でなかったら、exploreList に検索条件を追加する
        const res = await axios.post(
          apiUrlPost,
          {
            search_condition: exploreList,
            page: PageNo,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.localJWT}`,
            },
          }
        );
        setTotalCount(res.data.total_count);
        dispatch(updateSensors(res.data.sensor_list));
      } catch (error) {
        console.log(error);
      }
    };
    dispatch(updateSubmitting(false));
    fetchData();
  }, [PageNo, exploreList,isUploaded]);

  const handleLinkClick = (event:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const href = (event.currentTarget as HTMLAnchorElement).getAttribute('href');
    if (href) {
      window.open(href, '_blank');
    }
  };

  return (
    <div>
      <div>
      
        <StructureThreeColumns
          bucket1={
            [<TextField
            label="機器検索"
            value={nameInputValue}
            onChange={handleChange}
            variant="standard"
            />
            ]
          }
                bucket2={[<PlantDropdownMenu key={key}/>]}
                bucket3={[<TagDropdownMenu key={key}/>]}
                onPageChange={handlePageChange}
              />
      </div>

      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHeaderCell}>
                プラント
              </TableCell>
              <TableCell className={styles.tableHeaderCell}>機器ID</TableCell>
              <TableCell className={styles.tableHeaderCell}>名称</TableCell>
              <TableCell className={styles.tableHeaderCell}>機器種類</TableCell>
              <TableCell className={styles.tableHeaderCell}>センサー種類</TableCell>
              <TableCell className={styles.tableHeaderCell}>閾値</TableCell>
              <TableCell className={styles.tableHeaderCell}>予測タグ</TableCell>
              <TableCell className={styles.tableHeaderCell}>グラフ</TableCell>
              <TableCell className={styles.tableHeaderCell}>
                編集
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sensors.slice(0).map((sensor) => (
              <TableRow
                key={sensor.machine_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={styles.tableCell}>
                <Avatar style={{backgroundColor:getColorByCharacter(sensor.plant__name_jp)}}>
                  {sensor.plant__name_jp.charAt(0)}
                </Avatar>
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {sensor.name}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {sensor.nickname_alert}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {sensor.machine_kind}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {sensor.sensor_kind}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {sensor.threshold}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {sensor.sensor_used_tag}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <Link
                    to={`/prediction/?machine_id=${sensor.machine_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleLinkClick}
                    className={styles.link}
                  >
                    <IconButton>
                    <AutoGraphIcon color="primary"/>
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell className={styles.tableCell}>
                <IconButton
                  color="primary"
                   onClick={() => handleOpenModal(sensor.machine_id)}
                >
                <EditIcon />
                </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {modalOpen && (
        <ModalContent
          machineId={selectedMachineId}
          handleCloseModal={handleCloseModal}
        />
      )}
      <BasicContainer className="container-stock__pagination mt-4">
        <Pagination
          dataCounts={total_count}
          setStart={handleSetStart}
          numberOfDisplaysPerpage={numberOfDisplaysPerpage}
          currentPage={page}
        />
      </BasicContainer>
    </div>
  );
};

export default Sensors;
