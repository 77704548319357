import React from "react";
import Grid from "@mui/material/Grid";

export default function Structure(props) {
  const buckets = {
    1: Array.isArray(props.bucket1) ? props.bucket1 : [],
    2: Array.isArray(props.bucket2) ? props.bucket2 : [],
    3: Array.isArray(props.bucket3) ? props.bucket3 : [],
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        {buckets["1"].map((component) => (
          <React.Fragment>{component}</React.Fragment>
        ))}
      </Grid>
      <Grid item xs={12} md={4}>
        {buckets["2"].map((component) => (
          <React.Fragment>{component}</React.Fragment>
        ))}
      </Grid>
      <Grid item xs={12} md={4}>
        {buckets["3"].map((component) => (
          <React.Fragment>{component}</React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
}
