import React, { useState, ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, makeStyles } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import { Typography, TextField, Button, IconButton,Box, Divider } from '@mui/material';
import { Dialog, DialogTitle, DialogContent,DialogActions } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit'; // パスワード変更のアイコン

export default function BasicSettings() {
  const navigate = useNavigate();
  const [isChangePasswordModal, setIsChangePasswordModal] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");
  const openPasswrdModal = () => {
    // ここで設定を保存するAPIを呼び出す処理を実装する
    setIsChangePasswordModal(true);
  };
  const closePasswrdModal = () => {
    // ここで設定を保存するAPIを呼び出す処理を実装する
    setIsChangePasswordModal(false);
  };
  const openMessageModal = () => {
    // ここで設定を保存するAPIを呼び出す処理を実装する
    setIsMessageModal(true);
  };
  const closeMessageModal = () => {
    // ここで設定を保存するAPIを呼び出す処理を実装する
    setIsMessageModal(false);
  };
  const onConfirmModal = () => {
    // ここで設定を保存するAPIを呼び出す処理を実装する
    setIsMessageModal(true);
  };
  const openConfirmModal = () => {
    // ここで設定を保存するAPIを呼び出す処理を実装する
    setIsConfirmModal(true);
  };
  const closeConfirmModal = () => {
    // ここで設定を保存するAPIを呼び出す処理を実装する
    setIsConfirmModal(false);
  };
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const submitSavePassword = () => {
    //
    axios.post(`${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/password_change/`, {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword
    },{headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.localJWT}`,
    }
  }).then((res) => {
      console.log(res.data);
      setDisplayMessage(res.data.message);
    }).catch((error) => {
      console.log(error);
      setDisplayMessage(error.response.data.message);
    });
    openMessageModal();
    closePasswrdModal();
    closeConfirmModal();
    setDisplayMessage("")
    //navigate("/");
  };

  return (
    <Box display="flex" justifyContent="space-between">
        {/* パスワード変更ボタン */}
        <Button variant="contained" color="primary" onClick={openPasswrdModal }>
          パスワード変更
        </Button>
        {/* パスワード変更のアイコン */}
        <IconButton color="primary" onClick={openPasswrdModal }>
          <EditIcon />
        </IconButton>
        <Dialog open={isChangePasswordModal} onClose={closePasswrdModal}>
      <DialogTitle>パスワード変更</DialogTitle>
      <DialogContent>
      <Box mb={2}>
        <TextField
          label="現在のパスワード"
          variant="outlined"
          type="password"
          fullWidth
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        </Box>
        <Box mb={2}>
        <TextField
          label="新しいパスワード"
          variant="outlined"
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        </Box>
        <Box mb={2}>
        <TextField
          label="新しいパスワード（確認用）"
          variant="outlined"
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        </Box>
        ・パスワードは8文字以上で設定してください。
      </DialogContent>
      <DialogActions>
        <Button onClick={closePasswrdModal}>キャンセル</Button>
        <Button onClick={openConfirmModal} color="primary">保存</Button>
      </DialogActions>
    </Dialog>
    <Dialog open={isConfirmModal} onClose={closeConfirmModal}>
      <DialogContent>本当に変更してもいいですか？</DialogContent>
      <DialogActions>
        <Button onClick={closeConfirmModal} color="primary">キャンセル</Button>
        <Button onClick={submitSavePassword} color="primary">変更</Button>
      </DialogActions>
    </Dialog>
    <Dialog open={isMessageModal} onClose={closePasswrdModal}>
      <DialogContent>
        {displayMessage}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeMessageModal}>閉じる</Button>
      </DialogActions>
    </Dialog>
    
      </Box>
      
  );
}
