import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import axios from "axios";
const TagDetail = (props: any) => {
    const [tagLen,setTagLen] = useState(0)
    useEffect(() => {
        //const machine_id = props.machine_id;
        const apiUrlGet = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/tag_cls/${props.tag_id}/`;
        axios.get(apiUrlGet, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        }).then((response) => {
            console.log(response.data);
            const apiUrlGet = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/machine_tag_all/`;
            const info = response.data;
            const tag_name = response.data.name;
            axios.get(apiUrlGet, {
                headers: {
                    Authorization: `JWT ${localStorage.localJWT}`,
                },
            }).then((response) => {
                const values = Object.values(response.data);
                const tag_length = values
                .filter((value: any) => value.tag_name === tag_name)
                setTagLen( tag_length.length)
            });
        }).catch((error) => {
            console.log(error);
        });
    }, [props.tag_id]);
    return (
        <Typography variant="h6" className="sensor-info-label">
            {tagLen}個のセンサーが登録されています。
        </Typography>
    )
}
export default TagDetail;