import * as React from "react";
import { useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SensorsIcon from "@mui/icons-material/Sensors";
import { Routes, Route, NavLink } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

export default function NavigationBar() {
  //   return <List component="nav">{mainListItems}</List>;
  return (
    <List component="nav">
      {" "}
      <React.Fragment>
        {/* Dashbord */}
        <NavLink
          to="/home"
          style={({ isActive }) => {
            return {
              color: isActive ? "blue" : "black",
              textDecoration: "none",
              background: isActive ? "#D3D3D3" : "",
              display: "block",
              width: "100%", // 固定したい幅を指定
              marginTop: "15px", // 固定したいマージンを指定
              marginBottom: "20px", // 下部のマージンを設定
              marginLeft: "20px", // 左側のマージンを設定
              marginRight: "40px", // 右側のマージンを設定
            };
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </NavLink>
        {/* Sensor */}
        <NavLink
          to="/sensor?page=1"
          style={({ isActive }) => {
            return {
              color: isActive ? "blue" : "black",
              textDecoration: "none",
              background: isActive ? "#D3D3D3" : "",
              display: "block",
              width: "100%", // 固定したい幅を指定
              marginTop: "15px", // 固定したいマージンを指定
              marginBottom: "20px", // 下部のマージンを設定
              marginLeft: "20px", // 左側のマージンを設定
              marginRight: "40px", // 右側のマージンを設定
            };
          }}
        >
          <ListItemButton>
            <ListItemIcon >
              <SensorsIcon />
            </ListItemIcon>
            <ListItemText primary="Sensor" />
          </ListItemButton>
        </NavLink>
        {/* report */}
        <NavLink
          to="/report"
          style={({ isActive }) => {
            return {
              color: isActive ? "blue" : "black",
              textDecoration: "none",
              background: isActive ? "#D3D3D3" : "",
              display: "block",
              width: "100%", // 固定したい幅を指定
              marginTop: "15px", // 固定したいマージンを指定
              marginBottom: "20px", // 下部のマージンを設定
              marginLeft: "20px", // 左側のマージンを設定
              marginRight: "40px", // 右側のマージンを設定
            };
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <TextSnippetIcon />
            </ListItemIcon>
            <ListItemText primary="Report" />
          </ListItemButton>
        </NavLink>
        {/* Setting */}
        <NavLink
          to="/setting"
          style={({ isActive }) => {
            return {
              color: isActive ? "blue" : "black",
              textDecoration: "none",
              background: isActive ? "#D3D3D3" : "",
              display: "block",
              width: "100%", // 固定したい幅を指定
              marginTop: "15px", // 固定したいマージンを指定
              marginBottom: "20px", // 下部のマージンを設定
              marginLeft: "20px", // 左側のマージンを設定
              marginRight: "40px", // 右側のマージンを設定
            };
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Setting" />
          </ListItemButton>
        </NavLink>
      </React.Fragment>
    </List>
  );
}
