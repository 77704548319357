import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Button, styled } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axios from "axios";
import GenericTemplate from "../genericTemplate/GenericTemplate";
import DownloadIcon from '@mui/icons-material/Download';
// スタイルを定義したコンポーネントを作成
const apiUrl = process.env.REACT_APP_DEV_API_URL;
const StyledButton = styled(Button)(({ theme }) => ({
    mt: 3,
    mb: 2,
    ml: 1,
    mr: 1,
    transition: `background-color ${theme.transitions.duration.shortest}ms`,
    '&:hover': {
        backgroundColor: '#4caf50',
        color: '#ffffff',
    },
}));
function ConfirmModal({ isOpen, onClose, onConfirm }:
    { isOpen: boolean; onClose: () => void; onConfirm: () => void }) {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Report Download</DialogTitle>
            <DialogContent>
                <p>機器情報変更テンプレートをダウンロードしますか？</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <Button onClick={onConfirm} variant="contained" color="primary">
                    ダウンロード
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const ChangeMachineInfo = (props: any) => {
    // Downloadボタン押下時の処理
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);
    const handleOpenDownloadModal = () => {
        setIsDownloadModalOpen(true);
    };
    const handleCloseDownloadModal = () => {
        setIsDownloadModalOpen(false);
    };
    const handleDownloadConfirm = async () => {
        // 確認ボタンがクリックされた時の処理
        const response = await axios.get(`${apiUrl}ai_cosmo/api/machine_setting_list/`, {
            responseType: 'blob',
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        //const downloadUrl = URL.createObjectURL(response.data);
        //const downloadLink = document.createElement('a');
        //downloadLink.href = downloadUrl;
        //downloadLink.download = 'download_file.zip';
        //downloadLink.click();
        //URL.revokeObjectURL(downloadUrl);
        //console.log('Confirmed');
        handleCloseDownloadModal();
    };
    useEffect(() => {

    }, []);
    return (
        <div>
            <Typography variant="h6" className="sensor-info-label">
                機器情報変更
            </Typography>
            <StyledButton
                variant="outlined"
                sx={{ mt: 3, mb: 2, ml: 1, mr: 1 }}
                onClick={handleOpenDownloadModal}
                endIcon={<DownloadIcon />}
            >
                テンプレートダウンロード
            </StyledButton>
            <ConfirmModal
                isOpen={isDownloadModalOpen}
                onClose={handleCloseDownloadModal}
                onConfirm={handleDownloadConfirm} />
        </div>
    )

}
export default ChangeMachineInfo;