import React, { useState, useEffect, ChangeEvent } from "react";
import { FormControl, InputLabel, MenuItem, makeStyles } from "@mui/material";
import axios from "axios";
import {useSelector, useDispatch } from "react-redux";
import { sensorSearch, updatePlant } from "./sensorSearchSlice";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     border: "1px solid gray",
//     borderRadius: 4,
//     padding: theme.spacing(1),
//   },
// }));

function PlantDropdownMenu() {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const stats = useSelector(sensorSearch);
  const [selectedOption, setSelectedOption] = useState("");
  
  useEffect(() => {
    const newPlantData = {
      plant_id: 0,
      plant_name: "all",
    };
    dispatch(updatePlant(newPlantData));
  }, []);

  //plant一覧を取得
  const apiUrlGet = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/plant_list/`;
  const [loading, setLoading] = useState(true);
  const [plantMaster, setPlantMaster] = useState<{
    [key: string]: { [key: string]: any };
  } | null>(null);

  useEffect(() => {
    axios
      .get(apiUrlGet, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.localJWT}`,
        },
      })
      .then((response) => {
        setPlantMaster(response.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);
  const handleOptionChange = (event: SelectChangeEvent) => {
    //もしkeyが-1の場合は全選択にする。
    setSelectedOption(event.target.value as string);
    if (plantMaster) {
      const intSec = parseInt(event.target.value as string, 10);
      if (event.target.value === "-1") {
        const newPlantData = {
          plant_id: 0,
          plant_name: "all",
        };
        dispatch(updatePlant(newPlantData));
      } else {
        const newPlantData = {
          plant_id: plantMaster[intSec].plant_id,
          plant_name: plantMaster[intSec].name_jp,
        };
        dispatch(updatePlant(newPlantData));
      }
      // selectedPlantを使用して必要な処理を行う
    } else {
      const newPlantData = {
        plant_id: 0,
        plant_name: "all",
      };
      dispatch(updatePlant(newPlantData));
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="dropdown-label">プラント名</InputLabel>
      <Select
        labelId="dropdown-label"
        value={selectedOption}
        onChange={handleOptionChange}
      >
        <MenuItem value="-1">全部</MenuItem>
        {plantMaster &&
          Object.keys(plantMaster).map((key) => (
            <MenuItem key={key} value={key}>
              {plantMaster[key].name_jp}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default PlantDropdownMenu;
