import React, { useState, useLayoutEffect } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { PROPS_MAILLIST } from "../types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type Props = {
  show: boolean;
  handleClose: () => void;
  id: string;
};

const apiUrl = process.env.REACT_APP_DEV_API_URL;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const email_delete = async (id: string, value: string | null) => {
  try {
    const res = await axios.post(
      `${apiUrl}ai_cosmo/api/email_receiver/${id}/delete/`,
      {
        updated_at: value,
      },
      {
        headers: {
          Authorization: `JWT ${localStorage.localJWT}`,
        },
      }
    );
    return Promise.resolve(res.data);
  } catch (error: any) {
    alert(error.response.data.detail);
    return Promise.reject(error);
  }
};

export const email_receiver_id = async (value: string) => {
  const res = await axios.get(`${apiUrl}ai_cosmo/api/email_receiver/${value}`, {
    headers: {
      Authorization: `JWT ${localStorage.localJWT}`,
    },
  });
  return res.data;
};

const ModalMailDelete: React.FC<Props> = ({ show, handleClose, id }) => {
  // メール情報
  const [mailinfo, setMailList] = useState<PROPS_MAILLIST>();
  const [updateTime, setUpdateTime] = useState<string | null>(null);

  useLayoutEffect(() => {
    if (show) {
      email_receiver_id(id)
        .then((result: PROPS_MAILLIST) => {
          setMailList(result);
          setUpdateTime(result.updated_at);
        })
        .catch((error: unknown) => {});
    }
  }, [show]);

  return (
    <>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"削除確認"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {mailinfo?.email}を削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() =>
              email_delete(id, updateTime)
                .then((result) => {
                  handleClose();
                })
                .catch((error: unknown) => {})
            }
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalMailDelete;
