import GenericTemplate from "../../genericTemplate/GenericTemplate";
import React, { useState ,ReactNode } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import AddRowTable from "../../tabSettings/tabSettings";
import BasicSettings from "../../basicSettings/basicSettings";
import ChangeMachineInfo from "../../tabSettings/changeMachineInfomation";
interface TabPanelProps {
  children: ReactNode;
  value: number;
  index: number;
}

const TabPanel = ({ children, value, index }: TabPanelProps) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};



export const SettingPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <GenericTemplate title="Setting">
      <div style={{ display: 'flex' }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="基本設定" />
          <Tab label="機器情報一括更新" />
          <Tab label="タグ設定" />
          <Tab label="インテグレーション" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <BasicSettings/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ChangeMachineInfo/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AddRowTable/>
        </TabPanel>
        <TabPanel value={value} index={3}>
          Under Developing
        </TabPanel>
      </div>
    </GenericTemplate>
  );
};
