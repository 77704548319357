import React, { useState, useEffect } from "react";
import axios from "axios";
import "./individual_style.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const SensorInfo = (props: any) => {
  //axiosでAPIを叩いて、データを取得する。methodはgetで、URLはhttp://localhost:8052/api/sensor/1/で、
  // json形式で取得する。取得したデータはsensorDataに格納する。

  const [sensorData, setSensorData] = useState<{
    machine_name: string;
    sensor_kind: string;
    sensor_used_tag: string;
    threshold_alert: number;
    machine_kind: string;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    //const machine_id = props.machine_id;
    const apiUrlGet = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/alert_settings/${props.machine_id}/`;
    setLoading(true);
    axios
      .get(apiUrlGet, {
        headers: {
          Authorization: `JWT ${localStorage.localJWT}`,
        },
      })
      .then((response) => {
        setSensorData(response.data);
        setLoading(false);
        console.log(sensorData);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    //consoleに出力する
  }, [props.machine_id]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }
  if (!sensorData) {
    return <div>No data available</div>;
  }
  return (
    <div className="sensor-info-container">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">センサー名</TableCell>
              <TableCell align="center">マシン種類</TableCell>
              <TableCell align="center">センサー種類</TableCell>
              <TableCell align="center">予測使用タグ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={sensorData.machine_name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center">{sensorData.machine_name}</TableCell>
              <TableCell align="center">{sensorData.machine_kind}</TableCell>
              <TableCell align="center">{sensorData.sensor_kind}</TableCell>
              <TableCell align="center">{sensorData.sensor_used_tag}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SensorInfo;
