import React, { useState, ChangeEvent,useEffect } from "react";
import { FormControl, InputLabel, MenuItem, makeStyles } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useSelector,useDispatch } from "react-redux";
import { selectStats, updateMachineType } from "./statsSlice";
import axios from "axios";
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     border: "1px solid gray",
//     borderRadius: 4,
//     padding: theme.spacing(1),
//   },
// }));

function SensorDropdownMenu() {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const [tag_list, setOptionsSelect] = useState<{ machineTypeId: string, machineTypeName: string }[]>([]);
  useEffect(() => {
    const apiUrlGET = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/machine_kind/`;
    axios.get(apiUrlGET, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    }).then((res) => {
      console.log(res.data);
      const values = Object.values(res.data).map((item:any) => ({
        machineTypeId: item.kind_id,
        machineTypeName: item.machine_kind, // もしくは適切なプロパティ名に置き換えてください
      }));
      setOptionsSelect(values);
    }).catch((error) => console.error(error));;
  },[])


  const stats = useSelector(selectStats);
  const [selectedOption, setSelectedOption] = useState("");
  useEffect(() => {
    const newPlantData = {
      machine_type_id: 0,
      machine_type_name: "all",
    };
    dispatch(updateMachineType(newPlantData));
  }, []);
  
  const machineTypeList = tag_list.map(item => ({
    machineTypeId: item.machineTypeId,
    machineTypeName: item.machineTypeName
  }));
  // const handleOptionChange = (event: ChangeEvent<{ value: unknown }>) => {
  //   setSelectedOption(event.target.value as string);
  // };
  const handleOptionChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value as string);
    const intSec = parseInt(event.target.value as string, 10);
    if (event.target.value === "-1") {
      const newPlantData = {
        machine_type_id: 0,
        machine_type_name: "all",
      };
      dispatch(updateMachineType(newPlantData));
    }else {
      const newPlantData = {
        machine_type_id: machineTypeList[intSec].machineTypeId,
        machine_type_name: machineTypeList[intSec].machineTypeName,
      };
      dispatch(updateMachineType(newPlantData));
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="dropdown-label">機器種類</InputLabel>
      <Select
        labelId="dropdown-label"
        value={selectedOption}
        onChange={handleOptionChange}
      > 
        <MenuItem value="-1">-</MenuItem>
        {Object.keys(machineTypeList).map((key) => (
            <MenuItem key={key} value={key}>
              {machineTypeList[parseInt(key,10)].machineTypeName}
            </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SensorDropdownMenu;
