import React from 'react';

import VerticalNav3 from '../parts/vertical-navs/VerticalNav3';
import StructureRightSidebar from '../parts/__structures/StructureRightSidebar';
import StructureThreeColumns from '../parts/__structures/StructureThreeColumns';
import StructureLeftSidebar from '../parts/__structures/StructureLeftSidebar';

import PlantDropdownMenu from './PlantDropDown';
import SensorDropdownMenu from './SensorDropDown';
import TagDropdownMenu from './TagDropDown';
import StatsDropdownMenu from './StatsDropDown';
import PieChart from './CircleGraph';
import SortableTable from "./SortTable"

import { useSelector, useDispatch } from "react-redux";
import {
  selectStats,
} from "./statsSlice";

const meta = {};

export default function Dashboard() {
  const stats = useSelector(selectStats);
  return (
    <React.Fragment>
      <div>
        <StructureLeftSidebar
          bucket2={[
            <StructureThreeColumns
              bucket1={[<PlantDropdownMenu />]}
              bucket2={[<SensorDropdownMenu />]}
              bucket3={[<TagDropdownMenu />]}
            />
          ]}
          bucket1={[<StatsDropdownMenu />]}
        />
      </div>
      <div>
        <StructureLeftSidebar
          bucket1={[<PieChart />]}
          bucket2={[<SortableTable />]}
        />
      </div>
    </React.Fragment>
  );
}
