import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";

export const statsSlice = createSlice({
  name: "stats",
  initialState: {
    plant_id: 0,
    plant_name: 'all',
    machine_type_id: 0,
    machine_type_name: '-',
    tag_id:0,
    tag_name:'-',
    stats_type:'exceed_no',
    page:1
  },
  reducers: {
    updatePlant: (state, action) => {
      const newPlant = action.payload;
      // 更新したセンサーをstate内のセンサーリストから探し、置換する処理を記述する
      state.plant_id = newPlant.plant_id;
      state.plant_name = newPlant.plant_name;
    },
    updateTag: (state, action) => {
      const newPlant = action.payload;
      // 更新したセンサーをstate内のセンサーリストから探し、置換する処理を記述する
      state.tag_id = newPlant.tag_id;
      state.tag_name = newPlant.tag_name;
    },
    updatePage: (state, action) => {
      const newPage = action.payload;
      // 更新したセンサーをstate内のセンサーリストから探し、置換する処理を記述する
      state.page = newPage;
    },
    updateMachineType: (state, action) => {
      const newPlant = action.payload;
      // 更新したセンサーをstate内のセンサーリストから探し、置換する処理を記述する
      state.machine_type_id = newPlant.machine_type_id;
      state.machine_type_name = newPlant.machine_type_name;
    },
    updateStatsMethod: (state, action) => {
      const newPlant = action.payload;
      // 更新したセンサーをstate内のセンサーリストから探し、置換する処理を記述する
      state.stats_type = newPlant.stats_type;
    },
  },
});

export const { updatePlant,updatePage,updateTag,updateStatsMethod,updateMachineType} = statsSlice.actions;

export const selectStats = (state: RootState) => state.stats;
export default statsSlice.reducer;