import React, { useEffect, useState, ChangeEvent } from "react";
import GenericTemplate from "../../genericTemplate/GenericTemplate";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from "@mui/icons-material/Delete";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import EditIcon from "@mui/icons-material/Edit";
import ModalMailRegister from "../../modal/mailRegister";
import ModalMailEditor from "../../modal/mailEditor";
import ModalMailDelete from "../../modal/mailDelete";
import { Button, styled } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { PROPS_MAILLIST } from "../../types";
import Pagination from "@mui/material/Pagination";
import usePagination from "./Pagination";

const apiUrl = process.env.REACT_APP_DEV_API_URL;

// スタイルを定義したコンポーネントを作成
const StyledButton = styled(Button)(({ theme }) => ({
  mt: 3,
  mb: 2,
  ml: 1,
  mr: 1,
  transition: `background-color ${theme.transitions.duration.shortest}ms`,
  '&:hover': {
    backgroundColor: '#4caf50',
    color: '#ffffff',
  },
}));

// const response = await axios.post(`${apiUrl}ai_cosmo/api/email_receiver_all`);
export const email_receiver_all = async () => {
  const res = await axios.get(`${apiUrl}ai_cosmo/api/email_receiver_all/`, {
    headers: {
      Authorization: `JWT ${localStorage.localJWT}`,
    },
  });
  return res.data;
};

function ConfirmModal({ isOpen, onClose, onConfirm }:
  { isOpen: boolean; onClose: () => void; onConfirm: () => void }) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Report Download</DialogTitle>
      <DialogContent>
        <p>最新のレポートをダウンロードしますか？</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          ダウンロード
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const MailPage = () => {
  // メーリングリスト情報
  const [maillist, setMailList] = useState<PROPS_MAILLIST[]>([]);

  //モーダル関係
  const [show_register, setShowRegister] = useState<boolean>(false);
  const handleRegisterClose = (): void => setShowRegister(false);
  const handleRegisterShow = (): void => setShowRegister(true);

  const [show_editor, setShowEditor] = useState<boolean>(false);
  const handleEditorClose = (): void => setShowEditor(false);
  const handleEditorShow = (): void => setShowEditor(true);

  const [show_delete, setShowDelete] = useState<boolean>(false);
  const handleDeleteClose = (): void => setShowDelete(false);
  const handleDeleteShow = (): void => setShowDelete(true);

  //クリックしたkey情報
  const [click_key, setClickKey] = useState<number>(0);

  //ページネーション
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(maillist.length / PER_PAGE);
  const _DATA = usePagination(maillist, PER_PAGE);

  const handleChange = (e: any, p: any) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    email_receiver_all()
      .then((result: PROPS_MAILLIST[]) => {
        setMailList(result);
      })
      .catch((error: unknown) => { });
  }, [show_register, show_editor, show_delete]);

  // Downloadボタン押下時の処理
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);
  const handleOpenDownloadModal = () => {
    setIsDownloadModalOpen(true);
  };
  const handleCloseDownloadModal = () => {
    setIsDownloadModalOpen(false);
  };
  const handleDownloadConfirm = async () => {
    // 確認ボタンがクリックされた時の処理
    const response = await axios.get(`${apiUrl}ai_cosmo/api/report_download/`, {
      responseType: 'blob',
      headers: {
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    });
    const downloadUrl = URL.createObjectURL(response.data);
    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.download = 'download_file.zip';
    downloadLink.click();
    URL.revokeObjectURL(downloadUrl);
    console.log('Confirmed');
    handleCloseDownloadModal();
  };

  return (
    <>
      <GenericTemplate title="Mail" key="mail">
        {/* <Grid container spacing={2}>
        <Grid item xs={12}> */}
        <Paper
          sx={{
            p: 2,
            display: "block",
            flexDirection: "column",
            minWidth: 600,
            maxWidth: 600,
          }}
        ><StyledButton
          variant="outlined"
          sx={{ mt: 3, mb: 2, ml: 1, mr: 1 }}
          onClick={handleOpenDownloadModal}
          endIcon={<DownloadIcon />}
        >
            最新のレポート
          </StyledButton>
          <ConfirmModal
            isOpen={isDownloadModalOpen}
            onClose={handleCloseDownloadModal}
            onConfirm={handleDownloadConfirm} />
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                sx={{ mt: 4, mb: 2, ml: 1, mr: 1 }}
                variant="h6"
                component="div"
              >
                Weekly Report 送信先
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                sx={{ mt: 3, mb: 2, ml: 1, mr: 1 }}
                // onClick={handleOpen}
                onClick={() => handleRegisterShow()}
              >
                メールアドレス追加
              </Button>
            </Grid>
          </Grid>

          <List>
            {_DATA.currentData().map((mail: any, i: any) => (
              <ListItem
                key={mail.receiver_id}
                onClick={() => setClickKey(mail.receiver_id)}
                secondaryAction={
                  <>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      sx={{
                        marginRight: "5px",
                      }}
                      onClick={() => handleEditorShow()}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      sx={{
                        marginRight: "5px",
                      }}
                      onClick={() => handleDeleteShow()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                }
              >
                <ListItemText
                  key={mail.receiver_id}
                  primary={mail.email}
                  secondary={mail.name}
                />
              </ListItem>
            ))}
          </List>
          <Grid container justifyContent="center">
            <Grid item>
              <Pagination
                count={count}
                // size="large"
                page={page}
                // variant="outlined"
                // shape="rounded"
                onChange={handleChange}
                color="secondary"
              />
            </Grid>
          </Grid>
        </Paper>
        {/* </Grid>
      </Grid> */}
      </GenericTemplate>
      <ModalMailRegister
        show={show_register}
        handleClose={handleRegisterClose}
      />
      <ModalMailEditor
        show={show_editor}
        handleClose={handleEditorClose}
        id={click_key.toString()}
      />

      <ModalMailDelete
        show={show_delete}
        handleClose={handleDeleteClose}
        id={click_key.toString()}
      />
    </>
  );
};
