import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";

export const statsTableSlice = createSlice({
  name: "statsTable",
  initialState: {
    statsTable: [
      {
        machine_name:"",
        machine_id:0,
        exceed_no:0,
        indicatorWarning:0,
        max_value:0,
        flagAlarm:false,
        flagWarning:false,
        dateAlarm:"",
        dateWarning:"",
      }
    ],
  },
  reducers: {
    updateStatsTable: (state, action) => {
      const newStatsTable = action.payload;
      // 更新したセンサーをstate内のセンサーリストから探し、置換する処理を記述する
      state.statsTable = newStatsTable;
    },
  },
});

export const { updateStatsTable} = statsTableSlice.actions;

export const selectStatsTable = (state: RootState) => state.statsTable;
export default statsTableSlice.reducer;