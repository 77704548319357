import GenericTemplate from "../../genericTemplate/GenericTemplate";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Sensors from "../../sensor/Sensors";

export const SensorPage = () => {
  return (
    <GenericTemplate title="Sensor">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Sensors />
          </Paper>
        </Grid>
      </Grid>
    </GenericTemplate>
  );
};
