import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, ChangeEvent } from "react";
import { Link } from 'react-router-dom';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Checkbox, FormControlLabel } from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Copyright from "../../genericTemplate/Copyright";
// import Alert from "@mui/material/Alert";
// import Stack from "@mui/material/Stack";
const apiUrl = process.env.REACT_APP_DEV_API_URL;
const apiUrlGet = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/plant_list/`;
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export type User = {
  username: string;
  password: string;
  password_confirm: string;
  email: string;
  plant: string;
  perm_edit: boolean;
};

export type JWTCreate = {
  access: string;
  refresh: string;
};

export const SignUpPage = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>();

  const onSubmit: SubmitHandler<User> = async (data) => {
    const username = data.username;
    await axios
    .post(`${apiUrl}ai_cosmo/api/user_create/`, data).then((response) => {
      console.log(response);
      if (response.status === 201) {
        alert("アカウント申請が完了しました。メールを送信しましたのでご確認ください");
      }
      navigate("/");
    }).catch((error) => {
      console.log(error);
      alert(error.response.data.message);
    }
    );
  };

  const [plantMaster, setPlantMaster] = useState<{
    [key: string]: { [key: string]: any };
  } | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get(apiUrlGet, {
      })
      .then((response) => {
        setPlantMaster(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ユーザー登録
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username_register"
              type="username"
              label="ユーザーネーム"
              // name="email"
              // autoComplete="email"
              autoFocus
              {...register("username", { required: true })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              label="メールアドレス"
              {...register("email", { required: true })}
              // name="email"
              // autoComplete="email"
              autoFocus></TextField>
            <TextField
              margin="normal"
              required
              fullWidth
              // name="password"
              label="パスワード"
              type="password"
              id="password"
              {...register("password", { required: true })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              // name="password"
              label="パスワード（確認用）"
              type="password"
              id="password_confirm"
              autoComplete="current-password"
              {...register("password_confirm", { required: true })}
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel id="demo-simple-select-label">所属プラント</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                {...register("plant", { required: true })}
              >
                <MenuItem value={0}>無所属</MenuItem>
                {plantMaster &&
                  Object.keys(plantMaster).map((key) => (
                    <MenuItem key={key} value={plantMaster[key].plant_id}>
                      {plantMaster[key].name_jp}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox 
                  {...register("perm_edit", { required: false })}
                />
              }
              label="データ編集権限を要求"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              アカウント申請
            </Button>
            <Button
              component={Link}
              to="/"
              fullWidth
              variant="text"
              sx={{ mt: 3, mb: 2 }}
              size="small"
            >
              ログイン画面に戻る
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};
