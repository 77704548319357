import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useEffect, useState, ChangeEvent } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "../../genericTemplate/Copyright";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormControlLabel, Switch } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid from '@mui/material/Grid';

// import Alert from "@mui/material/Alert";
// import Stack from "@mui/material/Stack";
const apiUrl = process.env.REACT_APP_DEV_API_URL;
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export type User = {
  username: string;
  password: string;
};

export type JWTCreate = {
  access: string;
  refresh: string;
};

export const UserConfirmPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>();


  const [Users, setUsers] = useState<{
    [key: string]: { [key: string]: any };
  } | null>(null);
  const [isManager, setIsManager] = useState(false);
  const navigate = useNavigate();
  const [filteredActive, setFilteredActive] = useState(true);
  const handleToggleActiveRows = () => {
    setShowActiveRows(!showActiveRows);
  };
  const [showActiveRows, setShowActiveRows] = useState(true);

  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<{
    id: number, username: string, email: string, can_edit: boolean, is_active: boolean
  } | null>(null);
  const handleOpen = (user: any) => {
    setSelectedUser(user);
    setIsEditable(user?.can_edit);
    setIsActivate(user?.is_active);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isEditable, setIsEditable] = useState(selectedUser?.can_edit);
  const [isActivate, setIsActivate] = useState(selectedUser?.is_active);

  const handleToggleEdit = (event: any, newValue: any) => {
    setIsEditable(newValue);
  };
  const handleToggleActivate = (event: any, newValue: any) => {
    setIsActivate(newValue);
  };

  const [confirmModal, setConfirmModal] = useState(false);
  const confirmSubmit = () => {
    setConfirmModal(true);
  };
  const cancelSubmit = () => {
    setConfirmModal(false);
  };

  const [isUpdate, setIsUpdate] = useState(false);
  const Submitting = () => {
    const apiUrlPUT = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/user_confirm/`;
    axios.post(apiUrlPUT, {
      id: selectedUser?.id,
      username: selectedUser?.username,
      email: selectedUser?.email,
      can_edit: isEditable,
      is_active: isActivate,
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    }).then((res) => {
      console.log(res.data);
      setIsUpdate(true);
      setConfirmModal(false);
      handleClose();
    }
    ).catch((err) => {
      alert(
        "ユーザー情報の更新に失敗しました。"
      );
    }
    );
  };

  useEffect(() => {
    const apiUrlGET = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/user_confirm/`;
    axios.get(apiUrlGET, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    }).then((res) => {
      console.log(res.data);
      setUsers(res.data);
      setIsManager(true);
      setIsUpdate(false);
    }).catch((err) => {
      console.log(err);
    });
  }, [isUpdate]);


  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          ユーザー認証
        </Typography>
        {isManager ? (
          <TableContainer component={Paper} style={{ maxWidth: '800px' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showActiveRows}
                  onChange={handleToggleActiveRows}
                  color="primary"
                />
              }
              label="未承認のユーザーのみ表示する。"
            />
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ユーザー名</TableCell>
                  <TableCell>メールアドレス</TableCell>
                  <TableCell>編集権限</TableCell>
                  <TableCell>アクティベイト</TableCell>
                  <TableCell>編集</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Users && Object.keys(Users)
                  .filter(key => !showActiveRows || !Users[key].is_active)
                  .map((key) => (
                    <TableRow key={Users[key].id}>
                      <TableCell>{Users[key].username}</TableCell>
                      <TableCell>{Users[key].email}</TableCell>
                      <TableCell>{Users[key].can_edit ?
                        <CheckCircleIcon color="primary" /> :  // アクティブアイコン
                        <CancelIcon color="error" />           // インアクティブアイコン
                      }
                      </TableCell>
                      <TableCell>{Users[key].is_active ?
                        <CheckCircleIcon color="primary" /> :  // アクティブアイコン
                        <CancelIcon color="error" />           // インアクティブアイコン
                      }
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleOpen(Users[key])}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1">
            あなたは、マネージャーではありません。
          </Typography>
        )}
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>ユーザーの編集</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              編集権限
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isEditable}
                    onChange={handleToggleEdit}
                    color="primary"
                  />
                }
                label="有効"
              />
            </Grid>
            <Grid item xs={6}>
              アクティベート
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActivate}
                    onChange={handleToggleActivate}
                    color="primary"
                  />
                }
                label="有効"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={confirmSubmit} color="primary">
            変更
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmModal} onClose={cancelSubmit}>
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <p>変更しますか？</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelSubmit}>キャンセル</Button>
          <Button onClick={Submitting} color="error">変更</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider >
  );
};
