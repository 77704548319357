import * as React from "react";
import {
  Box,
  Grid,
  Paper,
  CssBaseline,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  Container,
  Button,
  Avatar,
  Link,
  Stack,
} from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NavigationBar from "./NavigationBar";
// import { withStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import Copyright from "./Copyright";
// import Stack from "@mui/material/Stack";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#283593", // プライマリカラー（シックな濃紺）
    },
    secondary: {
      main: "#1976d2", // セカンダリカラー（明るい青）
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif", // フォントファミリー
    fontSize: 16,
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          border: "1px solid #e0e0e0", // テーブルのボーダー
          borderRadius: "4px", // テーブルの角丸
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none", // セルの下部のボーダーをなくす
        },
        head: {
          backgroundColor: "#f5f5f5", // テーブルヘッダーの背景色
          fontWeight: "bold", // テーブルヘッダーのテキストを太字に
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(even)": {
            backgroundColor: "#f9f9f9", // 偶数行の背景色
          },
          "&.Mui-selected": {
            backgroundColor: "#e3f2fd", // 選択中の行の背景色
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#bbdefb", // 選択中の行をホバーしたときの背景色
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontWeight: "bold", // ソートラベルのテキストを太字に
          "&:hover": {
            color: "#1976d2", // ソートラベルをホバーしたときのテキスト色
          },
          "&.MuiTableSortLabel-active": {
            color: "#1976d2", // ソート中のテキスト色
          },
          "&.MuiTableSortLabel-iconDirectionAsc": {
            color: "#1976d2", // 昇順アイコンのテキスト色
          },
          "&.MuiTableSortLabel-iconDirectionDesc": {
            color: "#1976d2", // 降順アイコンのテキスト色
          },
        },
      },
    },
  },
});

// const StyledBadge = withStyles((theme) => ({
//   badge: {
//     backgroundColor: "#44b700",
//     color: "#44b700",
//     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//     "&::after": {
//       position: "absolute",
//       top: 0,
//       left: 0,
//       width: "100%",
//       height: "100%",
//       borderRadius: "50%",
//       animation: "$ripple 1.2s infinite ease-in-out",
//       border: "1px solid currentColor",
//       content: '""',
//     },
//   },
//   "@keyframes ripple": {
//     "0%": {
//       transform: "scale(.8)",
//       opacity: 1,
//     },
//     "100%": {
//       transform: "scale(2.4)",
//       opacity: 0,
//     },
//   },
// }))(Badge);

// export default function Dashboard() {
export interface GenericTemplateProps {
  children: React.ReactNode;
  title: string;
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const MainText = styled("span")(({ theme }) => ({
  fontSize: 28,
  fontFamily: "Lucida Handwriting", // カッコいいイタリックのフォントを指定
}));

const SubText = styled("span")(({ theme }) => ({
  fontFamily: "Freestyle Script", // 小文字で筆記体のフォントを指定
  textAlign: "right",
  fontSize: 18,
}));

const GenericTemplate: React.FC<GenericTemplateProps> = ({
  children,
  title,
}) => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "32px", // keep right padding when drawer closed
            }}
          >
            <Grid
              container
              alignItems="center"
              // spacing={2}
              direction="row"
              justifyContent="space-between"
              // justifyContent="space-between"
              // xs={12}
            >
              <Grid item xs={0.5}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                    marginRight: "36px",
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item xs={8.5} sx={{ justifyContent: "flex-start" }}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Paper
                    elevation={0}
                    component="img"
                    src={process.env.PUBLIC_URL + "/integnance_logo_c.webp"}
                    sx={{
                      width: "100%",
                      maxWidth: "25rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "transparent",
                    }}
                  />
                  <Box
                    sx={{
                      // bgcolor: "background.paper",
                      borderColor: "background.paper",
                      p: 1,
                      m: 1,
                      border: 3,
                      // width: "3.5rem",
                      // height: "3.5rem",
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" component="h2" align="center">
                      TR
                    </Typography>
                  </Box>
                  <Link
                    // href="https://www.janus.co.jp/Portals/0/images/energy/P-SADS_leaflet.pdf"
                    href={process.env.PUBLIC_URL + "P-SADS_leaflet.pdf"}
                    color="inherit"
                    target="_blank"
                  >
                    <Paper
                      elevation={0}
                      component="img"
                      src={process.env.PUBLIC_URL + "/p-sads.png"}
                      sx={{
                        // width: "100%",
                        maxWidth: "4rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // background: "transparent",
                      }}
                    />
                  </Link>
                </Stack>
              </Grid>
              <Grid item xs={3} sx={{ justifyContent: "flex-end" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  width="100%"
                  margin="1px"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      localStorage.removeItem("localJWT");
                      navigate("/");
                    }}
                    sx={{
                      m: 2,
                    }}
                  >
                    Logout
                  </Button>
                  <Avatar {...stringAvatar("COSMO Sakai")} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <NavigationBar />
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mb: 1 }}>
            <Grid item xs={12}>
              <Typography
                component="h2"
                sx={{ mt: 4, mb: 2 }}
                color="primary"
                noWrap
              >
                {title}
              </Typography>
            </Grid>
            {children}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default GenericTemplate;
