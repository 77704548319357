import React, {useEffect,useState} from 'react';
import { Modal, Box } from '@mui/material';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from "@mui/material";
import axios from "axios";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  zIndex:1050,
};

interface ThesholdModalProps {
    open: boolean;
    handleClose: () => void; // 引数を取らず、何も返さない関数の型を指定
    machineIdProp: number;
  }

  interface MachineItem {
    item_name: string;
    threshold_lower: number;
    threshold_upper: number;
    unit: string;
  }

function ThesholdModal({ open, handleClose,machineIdProp}: ThesholdModalProps) {
  const apiUrlMachineItems = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/machine_items/`; 
  const [machineItems, setMachineItems] = useState<MachineItem[]>([]);

  const handleThresholdChange = (index: number, field: keyof MachineItem, value: number) => {
    const updatedItems = [...machineItems];
    if (field === "threshold_lower" || field === "threshold_upper") {
      updatedItems[index][field] = value;
      setMachineItems(updatedItems);
    }
  };
  const handleSave = () => {
    // APIに保存するロジックをここに追加
    console.log("Saved data:", machineItems);
    axios.post(apiUrlMachineItems, machineItems, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    }).then((response) => {
      console.log(response.data);
    }).catch((error) => {
      console.error(error);
    });
  };
  useEffect(() => {
    axios.get(apiUrlMachineItems, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
      params: {
        machine_id: machineIdProp
      }
    }).then((response) => {
      setMachineItems(response.data);
      console.log(response.data);
    }).catch((error) => {
      console.error(error);
    });
  },[]
)
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h2 id="modal-modal-title">閾値の変更</h2>
        <TableContainer component={Paper}>
        <Table>
            <TableHead>
              <TableRow>
                <TableCell>変数名</TableCell>
                <TableCell>単位</TableCell>
                <TableCell>下限閾値</TableCell>
                <TableCell>上限閾値</TableCell>
                </TableRow>
            </TableHead>
                <TableBody>
              {machineItems.map((item, index) => (
                console.log(item),
                <TableRow key={index}>
                  <TableCell>{item.item_name}</TableCell>
                  <TableCell>{item.unit}</TableCell>
                  <TableCell>
                    <TextField
                      value={item.threshold_lower|| ''}
                      onChange={(e) => handleThresholdChange(index, "threshold_lower", Number(e.target.value))}
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={item.threshold_upper|| ''}
                      onChange={(e) => handleThresholdChange(index, "threshold_upper", Number(e.target.value))}
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
            </Table>
        </TableContainer>
        <Button onClick={handleSave} variant="contained" color="primary" sx={{ mt: 2 }}>
          保存
        </Button>
      </Box>
    </Modal>
  );
}

export default ThesholdModal;