import React, { useState, useEffect } from "react";
import InfoIcon from '@mui/icons-material/Info';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import { FormControlLabel, Switch } from '@mui/material';
import TagDetail from "./detailTable";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Icon } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import AlarmAddIcon from "@mui/icons-material/AlarmAdd";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import axios from "axios";
import Modal from '@mui/material/Modal';
import { styled } from '@mui/system';
// ...

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
`;

const ModalContent = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
}));


export default function AddEditRowTable() {
  const [rows, setRows] = useState([
    { cls_id: "1", name: "", machineCor: "", isEditing: false, updated_at: null, is_active: 1 }
  ]);
  // 実際のデータ送信処理を実行する
  const [showActiveRows, setShowActiveRows] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogRecoveryOpen, setConfirmDialogRecoveryOpen] = useState(false);
  const [confirmAddDialogOpen, setConfirmAddDialogOpen] = useState(false);

  const [selectedRowID,setSelecteRowId] = useState<string>("0");
  //Status Modalの開閉状態を管理する
  const [statusModalIsOpen, setStatusModalIsOpen] = useState(false);
  const [tagId, settagId] = useState<string>("");
  const openStatusModal = () => {
    setStatusModalIsOpen(true);
  };
  const closeStatusModal = () => {
    setStatusModalIsOpen(false);
  };

  useEffect(() => {
    const apiUrlGET = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/tag_cls_all/`;
    const res_act = axios.get(apiUrlGET, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    }).then((res) => {
      console.log(res.data);
      setRows(res.data);
    });
    setIsUpdate(false);
  }, [isUpdate]);


  const [newRow, setNewRow] = useState({
    cls_id: "0",
    name: "",
    machineCor: "0",
    isEditing: true,
    updated_at: null,
    is_active: 1
  });

  const handleToggleActiveRows = () => {
    setShowActiveRows(!showActiveRows);
  };

  const handleAddRow = () => {
    setConfirmAddDialogOpen(true);

  };
  const handleConfirmAdd = () => {
    const updatedRows = [...rows, newRow];
    setRows(updatedRows);
    //backendに送信
    const apiUrlGET = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/tag_cls/`;
    const res_act = axios.post(apiUrlGET, { name: newRow.name }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    }).then((res) => {
      console.log(res.data);
    }).catch((error) => {
      alert(error.response.data.message);
    });
    setIsUpdate(true);
    setConfirmAddDialogOpen(false);

  };

  const handleCancelAdd = () => {
    setConfirmDialogOpen(false);
  };

  const handleInputChange = (e: any, row: any) => {
    const { name, value } = e.target;
    const updatedRows = rows.map((r) =>
      r.cls_id === row.id ? { ...r, [name]: value } : r
    );
    setRows(updatedRows);
  };

  const handleDeleteRow = (id: string) => {
    console.log(id);
    setSelecteRowId(id);
    setConfirmDialogOpen(true);
  };
  const handleRecoveryRow = (id: string) => {
    setSelecteRowId(id);
    setConfirmDialogRecoveryOpen(true);
  };

  const handleConfirmDelete = (inputrow: any) => {
    //setRows((prevRows) => prevRows.filter((row) => row.cls_id !== inputrow.cls_id));
    //backendに送信
    const target_row = rows.filter((row) => row.cls_id == selectedRowID)[0];
    const apiUrlPostDelete = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/tag_cls/${target_row.cls_id}/delete/`;
    const res_act = axios.post(apiUrlPostDelete,
      { is_active: 0, updated_at: target_row.updated_at },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.localJWT}`,
        },
      }).then((res) => {
        console.log(res.data);
      }).catch((error) => {
        alert(error.response.data.message);
      });
    setIsUpdate(true);
    setConfirmDialogOpen(false);
  };

  const handleConfirmRecovery = (inputrow: any) => {
    //setRows((prevRows) => prevRows.filter((row) => row.cls_id !== inputrow.cls_id));
    //backendに送信
    const target_row = rows.filter((row) => row.cls_id == selectedRowID)[0];
    const apiUrlPostAct = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/tag_cls/${target_row.cls_id}/activate/`;
    const res_act = axios.post(apiUrlPostAct,
      { is_active: 1, updated_at: target_row.updated_at },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.localJWT}`,
        },
      }).then((res) => {
        console.log(res.data);
      }).catch((error) => {
        alert(error.response.data.message);
      });
    setIsUpdate(true);
    setConfirmDialogRecoveryOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false);
  };
  const handleCancelRecovery = () => {
    setConfirmDialogRecoveryOpen(false);
  };
  return (
    <TableContainer component={Paper}>
      <FormControlLabel
        control={
          <Switch
            checked={showActiveRows}
            onChange={handleToggleActiveRows}
            color="primary"
          />
        }
        label="表示切替"
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>有効</TableCell>
            <TableCell>タグ名</TableCell>
            <TableCell>詳細</TableCell>
            <TableCell>表示/非表示</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .filter((row) => (showActiveRows ? row.is_active : true))
            .map((row) => (
              <TableRow key={row.cls_id}>
                <TableCell>
                  {row.is_active ? (
                    <Icon component={CheckCircleOutlineIcon} color="primary" />
                  ) : (
                    <Icon component={DoNotDisturbAltIcon} color="error" />
                  )}
                </TableCell>
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<InfoIcon />}
                    sx={{ backgroundColor: '#00b894' }}
                    onClick={() => {
                      setStatusModalIsOpen(true);
                      settagId(row.cls_id);
                    }}
                  >
                  </Button>
                </TableCell>
                <TableCell>
                  {row.is_active ? (
                    <IconButton onClick={() => handleDeleteRow(row.cls_id)}>
                      <VisibilityOffIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleRecoveryRow(row.cls_id)}>
                      <VisibilityIcon />
                    </IconButton>
                  )}
                </TableCell>
                <Dialog open={confirmDialogOpen} onClose={handleCancelDelete}>
                  <DialogTitle>確認</DialogTitle>
                  <DialogContent>
                    <p>非表示にしますか？</p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancelDelete}>キャンセル</Button>
                    <Button onClick={() => handleConfirmDelete(row)} color="error">非表示</Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={confirmDialogRecoveryOpen} onClose={handleCancelRecovery}>
                  <DialogTitle>確認</DialogTitle>
                  <DialogContent>
                    <p>復活しますか？</p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancelRecovery}>キャンセル</Button>
                    <Button onClick={() => handleConfirmRecovery(row)} color="error">復活</Button>
                  </DialogActions>
                </Dialog>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <h6>新規タグ追加</h6>
      <TableRow>
        <TableCell>
          <TextField
            name="name"
            size="small"
            value={newRow.name}
            onChange={(e) => setNewRow({ ...newRow, name: e.target.value })}
          />
        </TableCell>
        <TableCell>
          <IconButton onClick={handleAddRow}>
            <AlarmAddIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <StyledModal
        open={statusModalIsOpen}
        onClose={closeStatusModal}>
        <div>
        <ModalContent>
          <TagDetail tag_id={tagId} />
          <Button variant="contained"
            onClick={closeStatusModal}
            sx={{ backgroundColor: '#00b894' }}>
            Close
          </Button>
          </ModalContent>
        </div>
      </StyledModal>
      <Dialog open={confirmAddDialogOpen} onClose={handleCancelAdd}>
        <DialogTitle>登録の確認</DialogTitle>
        <DialogContent>
          <p>登録してよろしいですか？</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmAdd}>登録</Button>
          <Button onClick={handleCancelAdd} color="error">キャンセル</Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}
