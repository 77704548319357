import React, { useState, ChangeEvent,useEffect } from "react";
import { FormControl, InputLabel, MenuItem, makeStyles } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useSelector,useDispatch } from "react-redux";
import { sensorSearch, updateTag } from "./sensorSearchSlice";
import axios from "axios";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     border: "1px solid gray",
//     borderRadius: 4,
//     padding: theme.spacing(1),
//   },
// }));

function TagDropdownMenu() {
  // const classes = useStyles();
  const dispatch = useDispatch();
  
  // const handleOptionChange = (event: ChangeEvent<{ value: unknown }>) => {
  //   setSelectedOption(event.target.value as string);
  // };
  const [tag_list, setOptionsSelect] = useState<{ tag_id: string, tag_name: string }[]>([]);
  const [TagSelect, setTagSelect] = useState<{ value: string, label: string }[]>([]);
  
  const stats = useSelector(sensorSearch);
  const [selectedOption, setSelectedOption] = useState("");
  useEffect(() => {
    const newPlantData = {
      tag_id: 0,
      tag_name: "all",
    };
    dispatch(updateTag(newPlantData));
  }, []);

  useEffect(() => {
    const apiUrlGET = `${process.env.REACT_APP_DEV_API_URL}ai_cosmo/api/tag_cls_list/`;
    axios.get(apiUrlGET, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    }).then((res) => {
      const values = Object.values(res.data).map((item:any) => ({
        tag_id: item.cls_id,
        tag_name: item.name, // もしくは適切なプロパティ名に置き換えてください
      }));
      const tmp_tag= values;
      const tmp_Object = tmp_tag.map(item => ({
        tag_id: item.tag_id,
        tag_name: item.tag_name
      }));
      setOptionsSelect(values);
    }).catch((error) => console.error(error));;
  },[])

  const tagObjectList = tag_list.map(item => ({
    tag_id: item.tag_id,
    tag_name: item.tag_name
  }));

  

  const handleOptionChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value as string);
    const intSec = parseInt(event.target.value as string, 10);
    if (event.target.value === "-1") {
      const newPlantData = {
        tag_id: 0,
        tag_name: "all",
      };
      dispatch(updateTag(newPlantData));
    }else {
      const newPlantData = {
        tag_id: tagObjectList[intSec].tag_id,
        tag_name: tagObjectList[intSec].tag_name,
      };
      console.log(event.target.value);
      dispatch(updateTag(newPlantData));
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="dropdown-label">タグ名</InputLabel>
      <Select
        labelId="dropdown-label"
        value={selectedOption}
        onChange={handleOptionChange}
      > <MenuItem value="-1">-  </MenuItem>
        {Object.keys(tagObjectList).map((key) => (
            <MenuItem key={key} value={key}>
              {tagObjectList[parseInt(key,10)].tag_name}
            </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default TagDropdownMenu;
