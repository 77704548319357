import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Button, LinearProgress } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import { Select } from "formik-mui";

interface RegisterDialog {
  email: string;
  name: string;
  plant: string;
}

type Props = {
  show: boolean;
  handleClose: () => void;
};

const apiUrl = process.env.REACT_APP_DEV_API_URL;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const email_register = async (value: RegisterDialog) => {
  const res = await axios.post(`${apiUrl}ai_cosmo/api/email_receiver/`, value, {
    headers: {
      Authorization: `JWT ${localStorage.localJWT}`,
    },
  }).then((res) => {
    console.log(res.data);
    return res.data;
  }).catch((error) => {
    const msg_err = error.response.data.detail;
    alert(msg_err);
  });
  
};

const ModalMailRegister: React.FC<Props> = ({ show, handleClose }) => {
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={show}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={show}>
          <Box sx={style}>
            <Typography variant="h6" gutterBottom>
              メールアドレスの追加
            </Typography>
            <Formik
              initialValues={{
                email: "",
                name: "",
                plant: "",
              }}
              validate={(values) => {
                const errors: Partial<RegisterDialog> = {};
                if (!values.email) {
                  errors.email = "入力して下さい";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = "メールアドレスを入力して下さい";
                }

                if (!values.name) {
                  errors.name = "入力して下さい";
                }
                return errors;
              }}
              onSubmit={async (values) => {
                email_register(values)
                  .then((result) => {
                    handleClose();
                  })
                  .catch((error: unknown) => {});
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <Grid container>
                    <Grid item>
                      <Field
                        component={TextField}
                        name="email"
                        type="email"
                        label="メールアドレス"
                        sx={{ mt: 4, mb: 1, ml: 1, mr: 1 }}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        component={TextField}
                        type="name"
                        label="名前"
                        name="name"
                        sx={{ mt: 1, mb: 1, ml: 1, mr: 1 }}
                      />
                      {/* {isSubmitting && <LinearProgress />} */}
                    </Grid>
                    <Grid item>
                      <FormControl
                        fullWidth
                        sx={{ mt: 1, mb: 5, ml: 1, mr: 5, pr: 10 }}
                      >
                        <InputLabel id="demo-simple-select-label" />
                        <Field
                          component={Select}
                          id="plant"
                          type="plant"
                          name="plant"
                          label="製油所"
                          labelId="plant"
                          validate={(plant: number) =>
                            !plant ? "選択して下さい" : undefined
                          }
                        >
                          <MenuItem value={1}>堺製油所</MenuItem>
                        </Field>
                      </FormControl>
                      {isSubmitting && <LinearProgress />}
                    </Grid>
                    <Grid container justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                        sx={{ mt: 1, mb: 1 }}
                        fullWidth
                      >
                        登録
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalMailRegister;
